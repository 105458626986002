import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEffectOnce } from '../../hooks/useEffectOnce';
import { theme } from '../../theme';
import BlendDetail from './BlendDetail';
import Link from './Link';
import Modal from 'react-modal';
import Text from './Text';

const BlendList = ({blends, isOpen, parent, close}) => {
    const {t} = useTranslation();
    const [reversedBlends, setReversedBlends] = useState(null);

    useEffectOnce(()=>{
        Modal.setAppElement(parent);

        const arr = [];
        blends.forEach(element => {
            arr.unshift(element)
        });
        setReversedBlends(arr);
    },[]);

    const closeModal = () => {
        close();
    }

    const renderBlend = (b) => {
      return <BlendDetail blend={b} key={b.id}/>
    }

    return (
      <Modal isOpen={isOpen} style={styles.root} shouldCloseOnOverlayClick={true}>
          <div style={styles.header}>
              <Text variant={'h3'} >{t('routes.info.blendList.traceability')}</Text>
              <Link onClick={closeModal} style={{textDecoration:'none'}} variant={'h5'}>{t('routes.info.originList.close')}</Link>
          </div>

          {reversedBlends && <div style={styles.content}>
                  {reversedBlends.map(b=>renderBlend(b))}
              </div>
          }


      </Modal>
    );
}

const styles = {
    root: {
        overlay: {
            backgroundColor: 'rgb(142,142,147, 0.9)',
        },
        content: {
            display:'flex',
            flexDirection:'column',
            margin:'auto',
            textAlign:'center',
            backgroundColor: theme.colors.white,
            borderWidth:0,
            height:'auto',
            padding:theme.spacing['spacing-04'],
        }
    },
    header:{
        display: 'flex',
        flexDirection: 'row',
        justifyContent:' space-between',
        marginBottom: theme.spacing['spacing-07'],
        marginTop: theme.spacing['spacing-08'],
    },
    content: {
        overflow:'auto'
    },

}

export default BlendList;
