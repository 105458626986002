import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import logo from '../assets/images/logo.svg'
import registrationOk from '../assets/images/gegistrationOk.svg'
import appleStoreButton from '../assets/images/App Store.svg'
import googlePlayButton from '../assets/images/Google Play.svg'
import Button from '../components/ui/Button';
import Input from '../components/ui/Input';
import Link from '../components/ui/Link';
import Modal from '../components/ui/Modal';
import Text from '../components/ui/Text';
import { useModal } from '../contexts/ModalContext';
import { useEffectOnce } from '../hooks/useEffectOnce';
import { theme } from '../theme';
import Switch from "react-switch";
import * as yup from 'yup';
import { ErrorMessage } from '@hookform/error-message';
import eyeClosed from '../assets/images/ic_eye_close.svg'
import eyeOpen from '../assets/images/ic_eye_open.svg'
import conf from '../config'

function Register() {
  const { register, watch, handleSubmit, formState: { errors} } = useForm({ });
  const watchPassword = watch('password')
  const [error, setError] = useState(null);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [success, setSuccess] = useState(false);
  const [apiToken, setApiToken] = useState(null);
  const [code, setCode] = useState(null);
  const [userId, setUserId] = useState(null);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const {t} = useTranslation();
  const {openModal} = useModal()

  useEffectOnce(()=>{
    const url = new URL(document.location.href);
    const search = new URLSearchParams(url.searchParams);
    setCode( search.get('code'));
    setApiToken( search.get('apiToken'));
    setUserId(search.get('userId'));
  },[])

  const isRegister = ()=> {
    return !!apiToken;
  }

  const onClick = async () => {
    try {
      let url;
      let body;
      setError(null);
      const headers= {
        'Content-Type': 'application/json',
        'app-id': conf.appId,
      };

      if(isRegister()) {
        url = conf.updatePasswordUrl;
        headers['Authorization'] = 'Bearer ' + apiToken;
        body= JSON.stringify({
          password: watchPassword
        });
      } else {
        url = ''+conf.resetPasswordUrl;
        url = url.replace('%userId%', userId);
        body= JSON.stringify({
            password: watchPassword,
            code
          })
      }

console.log(conf)
      const response = await fetch(url,
        {
          method: isRegister() ? 'POST' : 'PUT',
          headers,
          body,
        }
      )

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      setSuccess(true);
    }catch(error) {
      console.log(error)
      setError(t('routes.register.errors.exception'));
    }
  }

  const openTerms = () => {
    window.open(conf.TERMS_LINK, '_blank', 'noopener,noreferrer');
  };



  return (
    <div style={styles.root}>
      <Modal />
      <img alt='' src={logo} style={styles.logo}/>
      {!success &&
        <div style={styles.body}>
          <div style={styles.header}>
            <Text variant={'h3'} style={styles.title} color={theme.colors.darkGrey}>{isRegister() ? t('routes.register.title') : t('routes.register.changePasswordTitle')}</Text>
            <Text variant={'h5'} style={styles.subTitle} color={theme.colors.black}>{isRegister() ? t('routes.register.subTitle')  : t('routes.register.changePasswordSubTitle')}</Text>
          </div>
          <form style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{position:'relative'}}>
              <Input
                autoComplete={'new-password'}
                type={passwordShown ? 'text' : 'password'}
                placeholder={t('routes.register.passwordPlaceholder')}
                error={errors.password}
                {...register("password", {
                  validate: async v => {
                    const schema = yup.object().shape({
                      password: yup.string().required(t('routes.register.errors.passwordEmpty'))
                        .min(8, t('routes.register.errors.passwordTooShort'))
                        .test("isValidPass", " ", (value, context) => {
                          const hasUpperCase = /[A-Z]/.test(value);
                          const hasLowerCase = /[a-z]/.test(value);
                          const hasNumber = /[0-9]/.test(value);
                          let validConditions = 0;
                          const numberOfMustBeValidConditions = 3;
                          const conditions = [hasLowerCase, hasUpperCase, hasNumber];
                          conditions.forEach((condition) =>
                            condition ? validConditions++ : null
                          );
                          if (validConditions >= numberOfMustBeValidConditions) {
                            return true;
                          }
                          return false;
                        })
                    })
                    return await schema.isValid({ password: v }) || t('routes.register.errors.wrongFormat')
                  }
                })}
              />
              <span onClick={()=>setPasswordShown(prev=>!prev)} style={{...styles.eye, ...{'backgroundImage': passwordShown ?`url(${eyeClosed})` : `url(${eyeOpen})`}}}/>
            </div>
            <div style={{position:'relative'}}>
            <Input
              autoComplete={'new-password'}
              type={confirmPasswordShown ? 'text' : 'password'}
              placeholder={t('routes.register.confirmPasswordPlaceholder')}
              {...register("confirmPassword", {
                required: t('routes.register.errors.confirmPasswordEmpty'),
                minLength: { value: 8, message: t('routes.register.errors.passwordTooShort') },
                validate: {
                  checkEqual: v => v === watchPassword || t('routes.register.errors.passwordMismatch'),
                },
              })}
              errored={errors.confirmPassword}

            />
              <span onClick={()=>setConfirmPasswordShown(prev=>!prev)}style={{...styles.eye, ...{'backgroundImage': confirmPasswordShown ?`url(${eyeClosed})` : `url(${eyeOpen})`}}}/>

            </div>
            {isRegister() &&
              <div style={styles.terms}>
                <Text variant={'h5'} color={theme.colors.darkGrey}>{t('routes.register.termsLabel')} <Link variant={'h5'}
                                                                                                           onClick={openTerms}>{t('routes.register.termsLink')}</Link></Text>

                <Switch
                  onChange={() => setTermsAccepted(prev => !prev)}
                  checked={termsAccepted}
                  onColor={theme.colors.primary}
                  offColor={theme.colors.midGrey}
                  uncheckedIcon={false}
                  checkedIcon={false}
                />
              </div>
            }

            {(error || Object.keys(errors).length>0) && <div
              style={{
                marginBottom: theme.spacing['spacing-03'],
                flexDiretion: 'row',
                display: 'flex',
                width: '100%',
                justifyContent: 'center'
              }}>

              {errors.password &&
                <ErrorMessage
                  errors={errors}
                  name="password"
                  render={({ message }) => <Text variant={'p1'} color={theme.colors.warning}
                                                 style={{ marginRight: theme.spacing['spacing-03'] }}>{message}</Text>}
                />
              }

              {(!errors.password && errors.confirmPassword) &&
                <ErrorMessage
                  errors={errors}
                  name="confirmPassword"
                  render={({ message }) => <Text variant={'p1'} color={theme.colors.warning}
                                                 style={{ marginRight: theme.spacing['spacing-03'] }}>{message}</Text>}
                />
              }

              {error && <Text variant={'p1'} color={theme.colors.warning}
                                style={{ marginRight: theme.spacing['spacing-03'] }}>{error}</Text>
                }

            </div>
            }

            <div style={styles.buttonContainer}>
            {isRegister() && <Button disabled={!termsAccepted} type={'submit'} width={311} height={46}
                    onClick={handleSubmit(d => onClick(d))} text={t('routes.register.completeRegistration')}/>}
            {!isRegister() && <Button type={'submit'} width={311} height={46}
                                     onClick={handleSubmit(d => onClick(d))} text={t('routes.register.setNewPassword')}/>}
            </div>
          </form>
        </div>
      }
      {success && <div style={styles.body}>
        <div style={styles.registrationOkContainer}>
          <div style={styles.registrationOkContainerBody}>
            <img src={registrationOk} style={styles.registrationOk} alt={''}/>
            <Text variant={'h2'}>{isRegister() ? t('routes.register.registrationSuccessful') : t('routes.register.setPasswordSuccessful')}</Text>
          </div>
          <div style={styles.storebuttonsContainer}>
            <a href={'https://apps.apple.com/us/app/unblended/id1659646500'}>
              <img src={appleStoreButton}  alt={''} style={styles.storebutton}/>
            </a>
            <a href={'https://play.google.com/store/apps/details?id=it.unblended.android'}>
              <img src={googlePlayButton}  alt={''} style={styles.storebutton}/>
            </a>
          </div>
        </div>
      </div>}
    </div>
)}

const styles = {
  root: {
    textAlign: 'center',
    display: 'flex',
    height:'100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection:'column',
  },

  logo: {
    width: 220,
    height: 68,
    marginBottom: theme.spacing['spacing-06'],
  },

  body:{
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    flexDirection:'column',
    backgroundColor: theme.colors.lighterGrey,
    padding: theme.spacing['spacing-05'],
    borderRadius:10,
    width: 343,
  },

  header: {
    justifyItems: 'left'
  },
  title: {marginBottom:theme.spacing['spacing-05'], textAlign:'justify', textJustify: 'initial'},
  subTitle: { marginBottom:theme.spacing['spacing-05'], textAlign:'justify', textJustify: 'auto'},
  terms: {
    paddingTop: theme.spacing['spacing-05'],
    paddingBottom: theme.spacing['spacing-05'],
    width:'100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

  },
  buttonContainer: {
    marginTop: theme.spacing['spacing-05'],
  },
  registrationOkContainer:{
    height:455,
    display:'flex',
    alignItems:'center',
    flexDirection:'column',
    justifyContent:'space-between',
    padding: theme.spacing['spacing-05']
  },
  registrationOkContainerBody:{
    marginTop:100,
  },
  registrationOk:{
    height:80,
    width:80,
    marginBottom:theme.spacing['spacing-05']
  },
  storebuttonsContainer: {
    position:'relative',
    bottom:0,
    display:'flex',
    flexDirection: 'row'
  },
  storebutton: {
    width:150,
  },
  eye: {
    position:'absolute',
    right:10,
    top:20,
    zIndex:100,
    width:24,
    height:24,
  },

}


export default Register;
