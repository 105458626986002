import React, {Children} from 'react';
import Box from "./Box";

const HStack = (props) => {
    const {space, children} = props
    const childrenCount = Children.count(children)
    const isSingleChild = childrenCount === 1

    return (
        <div style={{display: 'flex', flexDirection: 'row', flex: 1, ...props.style}}>
            {isSingleChild ? children : children.map((child, i) => {
                const isLastElement = (childrenCount - 1) === i
                const spacing = space || 0
                return (
                    <Box key={i} style={{paddingRight: isLastElement ? 0 : spacing}}>{child}</Box>
                )
            })}
        </div>
    );
};

export default HStack;
