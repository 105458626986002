import React from 'react';
import {theme} from "../../theme";

const Input = React.forwardRef((props, ref) => {
  const {error} = props;
  const styles = {
    root: {
      display: 'flex',
      outline: 'none',
      color: theme.colors.black,
      backgroundColor: theme.colors.white,
      borderWidth: 1,
      border: 'solid',
      borderColor: error ? theme.colors.warning : theme.colors.lightGrey,
      borderRadius: 10,
      width: 311,
      marginBottom:theme.spacing['spacing-03'],
      paddingLeft: theme.spacing["spacing-05"],
      paddingRight: theme.spacing["spacing-05"],
      paddingTop: theme.spacing["spacing-05"],
      paddingBottom: theme.spacing["spacing-05"],
      ...theme.components.Text.variants.h5,
    }
  }


    return (
        <input
            ref={ref}
            {...props}
            style={{...styles.root, ...props.style}}
        />
    );

});


export default Input;
