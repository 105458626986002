export const statuses = {
    STEP_01: 'step-01',
    STEP_02: 'step-02',
    STEP_03: 'step-03',
    STEP_04: 'step-04',
    STEP_05: 'step-05',
    STEP_06: 'step-06',
    STEP_07: 'step-07',
    STEP_08: 'step-08',
    STEP_09: 'step-09',
    FINALIZED: 'finalized',
};

export const    modifiers =  {
    TEMPERATURE: 'temperature',
    TIME: 'time',
    RENNET: 'rennet',
    ENZYMES: 'enzymes',
    SALT: 'salt',
    PACKAGING: 'packaging',
}

export const roles = {
    ORIGIN: 'origin',
    CARRIER: 'carrier',
    PROCESSOR: 'processor',
    ISSUER: 'issuer',
    INTERNAL_SEASONER: 'internal-seasoner',
    EXTERNAL_SEASONER: 'external-seasoner',
    STORAGE_MANAGER: 'storage-manager',
}



