import React, { useCallback, useRef, useState } from 'react';
import { GoogleMap, HeatmapLayer, Marker, useJsApiLoader  } from '@react-google-maps/api';
import { useEffectOnce } from '../../hooks/useEffectOnce';

const UserMap = ({origin: user, selected, userLocations}) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyD2XHyM-8cRrKDRB9Ln5GnNw-Rj8V4N1v4',
  })
  const [center, setCenter] = useState();


  useEffectOnce(()=> {
    if(user) {
      user.blendRetrievalLocation ?
        setCenter({lat:user.blendRetrievalLocation.latitude, lng:user.blendRetrievalLocation.longitude}) :
        setCenter({lat:user.extraData.location.lat, lng:user.extraData.location.lon})
    }
  },[user])

  return isLoaded ? (
    <>
      <div style={styles.mapOver}>

      </div>
      <GoogleMap
        mapContainerStyle={styles.root}
        center={center}
        zoom={10}
        options={{
          disableDefaultUI:true,
        }}
      >
        { selected && <Marker position={center} /> }
        <></>
      </GoogleMap>

    </>
  ) : <></>
}

const styles = {
    root: {
      width: '100%',
      height: 290,
      background: 'linear-gradient(to bottom, #33ccff 0%, #ff99cc 100%)',
      zIndex:1,
    },
  mapOver: {
    width: '100%',
    height: 290,
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    background: 'linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.51) 100%)',
    zIndex:2,
  }
}

export default UserMap;
