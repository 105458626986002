import React from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from '../../theme';
import Text from './Text';
import moment from 'moment'
import {modifiers} from '../../const'

const ModifierDetails = ({label, values}) => {
    const {t} = useTranslation();

    const getValues = () => {
        if(!values?.length){
            return;
        }
        if(values[0].lotId){
            return values.map(v => <Text key={v.id} variant={'h6'} style={styles.value}>{t('routes.info.details.card.lot', {lotId: v.lotId})}</Text>)
        }

        const temperature = values.find(v=>v.category===modifiers.TEMPERATURE);
        const time = values.find(v=>v.category===modifiers.TIME);
        return <div style={styles.value}>
            <Text variant={'h6'} style={styles.value}>{temperature.amount}° - {moment.duration(time.amount, 'seconds').minutes()} {t('routes.info.details.card.minutes')}</Text>
        </div>
    }

    return (
        <div style={styles.root}>
            <Text style={styles.label} variant={'h6'} >{label}</Text>
            <div style={styles.valuesContainer}>{getValues()}</div>
        </div>
    );
};

const styles = {
    root: {
        backgroundColor: theme.colors.lighterGrey,
        padding:theme.spacing['spacing-04'],
        display:'flex',
        flexDirection:'row',
        width:268,
        justifyContent:'space-between',
        marginBottom: theme.spacing['spacing-04'],
        borderRadius:10,
    },
    label: {
        color: theme.colors.darkGrey
    },
    valuesContainer: {

    },
    value: {
        color: theme.colors.midGrey
    }

}

export default ModifierDetails;
