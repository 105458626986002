import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEffectOnce } from '../../hooks/useEffectOnce';
import { theme } from '../../theme';
import Link from './Link';
import Text from './Text';
import Modal from 'react-modal';
import facebook from '../../assets/images/fb.svg'
import ln from '../../assets/images/ln.svg'
import twitter from '../../assets/images/twitter.svg'
import copy from '../../assets/images/copy.png'
import {CopyToClipboard} from 'react-copy-to-clipboard';

const ShareDialog = ({isOpen, parent, close}) => {
    const {t} = useTranslation();

    useEffectOnce(()=>{
        Modal.setAppElement(parent);
    },[]);


    const closeModal = () => {
        close();
    }

    const share = (social) => {
        let url;
        switch (social) {
            case 'fb':
                url = 'https://www.facebook.com/sharer.php?u='+window.location.href.replace('http://localhost:3000/', 'https://stage.unblended.it/');
                break;
            case 'twitter':
                url = 'https://twitter.com/share?url='+window.location.href.replace('http://localhost:3000/', 'https://stage.unblended.it/')+'&text=Unblended'
                break;
            case 'ln':
                url = 'https://www.linkedin.com/shareArticle?url='+window.location.href.replace('http://localhost:3000/', 'https://stage.unblended.it/')+'&title=Unblended';
                break;
        }
        window.open(url, '_blank', 'noopener,noreferrer');
        closeModal();
    }
    return (
      <Modal isOpen={isOpen} style={styles.root}>
          <div style={styles.header}>
              <div style={styles.headerTop}>
                  <Text variant={'h3'} color={theme.colors.black}>{t('routes.info.shareDialog.title')}</Text>
                  <Link onClick={closeModal} style={{textDecoration:'none'}} variant={'h5'}>{t('routes.info.shareDialog.close')}</Link>
              </div>

          </div>
          <div style={styles.content}>
              <div style={styles.buttonContainer}>
                  <img src={facebook}  alt={''} style={styles.button} onClick={()=>share('fb')}/>
              </div>
              <div style={styles.buttonContainer}>
                  <img src={ln}  alt={''} style={{...styles.button, width:18, height:18}} onClick={()=>share('ln')}/>
              </div>
              <div style={styles.buttonContainer}>
                  <img src={twitter}  alt={''} style={{...styles.button, width:20, height:16}} onClick={()=>share('twitter')} />
              </div>
              <div style={styles.buttonContainer}>
                  <CopyToClipboard text={window.location.href}
                                   onCopy={() => closeModal()}>
                      <img src={copy}  alt={''} style={{...styles.button, width:20, height:20}} />
                  </CopyToClipboard>

              </div>
          </div>

      </Modal>
    );
}

const styles = {
    root: {
        overlay: {
            backgroundColor: 'rgb(142,142,147, 0.9)',
        },
        content: {
            display:'flex',
            flexDirection:'column',
            margin:'auto',
            textAlign:'center',
            backgroundColor: theme.colors.lighterGrey,

            borderWidth:0,
            padding:0,
            width:290,
            height:136,
            borderRadius:10,
        }

    },
    header: {
      display:'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',

    },
    headerTop: {
        display:'flex',
        flexDirection:'row',
        justifyContent: 'space-between',
        zIndex:3,
        width:'100%',
        padding:theme.spacing['spacing-05'],
    },
    content:{
        paddingLeft:theme.spacing['spacing-05'],
        paddingRight:theme.spacing['spacing-05'],
        display:'flex',
        flexDirection:'row',
        justifyContent: 'space-between',
        marginTop: theme.spacing['spacing-05'],
    },
    buttonContainer:{
        width:54,
        height: 54,
        backgroundColor:theme.colors.white,
        borderRadius: 55,
        textAlign: 'center',
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
    },
    button:{
        width:11,
        height: 20,
    },
}

export default ShareDialog;
