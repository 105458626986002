import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEffectOnce } from '../../hooks/useEffectOnce';
import { theme } from '../../theme';
import Link from './Link';
import UserMap from './UserMap';
import Text from './Text';
import Modal from 'react-modal';
import positionPin from '../../assets/images/positionPin.svg'
import _ from 'lodash';

const OriginList = ({origins, isOpen, parent, close}) => {
    const {t} = useTranslation();
    const [currentOrigin, setCurrentOrigin] = useState(origins[0])
    const [originSelected, setOriginSelected] = useState(false);

    useEffectOnce(()=>{
        Modal.setAppElement(parent);

    },[]);

    const renderOrigin = (origin) => {
        return <div  style={{...styles.origin, backgroundColor: originSelected && currentOrigin.id===origin.id ? theme.colors.lightGrey : theme.colors.lighterGrey}} key={origin.id}>
            {origin.extraData.socialLink && <Link variant={'h6'} onClick={()=>window.open(origin.extraData.socialLink, '_blank', 'noopener,noreferrer')}>{origin.name}</Link>}
            {!origin.extraData.socialLink && <Text variant={'h6'}>{origin.name}</Text>}
                <img src={positionPin}  alt={''} style={styles.positionPin} onClick={()=>{setCurrentOrigin(origin); setOriginSelected(true)}}/>
            </div>
    }

    const closeModal = () => {
        setOriginSelected(false);
        close();
    }
    return (
      <Modal isOpen={isOpen} style={styles.root}>
          <div style={styles.header}>
              <UserMap
                origin={currentOrigin}
                selected={originSelected}
                userLocations={
                    origins.map(o => {
                        if(o.blendRetrievalLocation) {
                            return {lat:o.blendRetrievalLocation.latitude, lng:o.blendRetrievalLocation.longitude}
                        } else {
                            return {lat:o.extraData.location.lat, lng:o.extraData.location.lon}
                        }
                    })}
              />
              <div style={styles.headerTop}>
                  <Text variant={'h3'} color={theme.colors.white}>{t('routes.info.originList.title')}</Text>
                  <Link onClick={closeModal} style={{color:theme.colors.white, textDecoration:'none'}} variant={'h5'}>{t('routes.info.originList.close')}</Link>
              </div>

          </div>
          <div style={styles.originsList}>
              {origins?.map(o => renderOrigin(o))}
          </div>

      </Modal>
    );
}

const styles = {
    root: {
        overlay: {
            backgroundColor: 'rgb(142,142,147, 0.9)',
        },
        content: {
            display:'flex',
            flexDirection:'column',
            margin:'auto',
            textAlign:'center',
            backgroundColor: theme.colors.white,

            borderWidth:0,
            padding:0,
            height:'auto',
        }

    },
    header: {
      display:'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      backgroundColor: 'red',
    },
    headerTop: {
        position:'absolute',
        top:theme.spacing['spacing-05'],
        left:theme.spacing['spacing-05'],
        right: theme.spacing['spacing-05'],
        display:'flex',
        flexDirection:'row',
        justifyContent: 'space-between',
        zIndex:3,
    },
    originsList :{
        padding:theme.spacing['spacing-05'],
        display:'flex',
        flexDirection:'column',
        paddingTop:theme.spacing['spacing-05'],
        overflow:'auto',
    },
    origin :{
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        alignItems:'center',
        backgroundColor: theme.colors.lighterGrey,
        borderRadius: 10,
        height: theme.spacing['spacing-08'],
        padding:theme.spacing['spacing-04'],
        marginBottom: theme.spacing['spacing-03'],

},
    positionPin: {
        width: 19,
        height: 19,
    }

}

export default OriginList;
