import React from 'react';

const Box = (props) => {
    return (
        <div style={{display: 'flex', flex: 1, ...props.style}}>
            {props.children}
        </div>
    );
};

export default Box;
