import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import logo from '../assets/images/logo.svg'
import Button from '../components/ui/Button';
import Card from '../components/ui/Card';
import Link from '../components/ui/Link';
import ModifierDetails from '../components/ui/ModifierDetails';
import OriginList from '../components/ui/OriginList';
import BlendList from '../components/ui/BlendList';
import ShareDialog from '../components/ui/ShareDialog';
import Text from '../components/ui/Text';
import { theme } from '../theme';
import mapStats from '../assets/images/mapStats.svg';
import blockchainLogo from '../assets/images/blockchainLogo.svg';
import _ from 'lodash';
import moment from 'moment';
import {modifiers, statuses} from '../const';
import {Oval} from 'react-loader-spinner'
import config from '../config';

function Info() {
  const [loaded, setLoaded] =  useState(false);
  const [originsShown, setOriginsShown] =  useState(false);
  const [blendListShown, setBlendListShown] =  useState(false);
  const [shareShown, setShareShown] =  useState(false);
  const [error, setError] =  useState(null);
  const [info, setInfo] =  useState(null);
  const {t} = useTranslation();
  const [width, setWindowWidth] = useState(0);
  const [paddingVal, setPaddingVal] = useState(0);



  const updateDimensions = () => {
    const width = window.innerWidth
    if(width < 700) {
      setWindowWidth(320)
      setPaddingVal(30)
    } else {
      setWindowWidth(700)
      setPaddingVal(140)
    }

  }

  const getInfo = async (apiUrl) => {
    try {
      const headers= {
        'Content-Type': 'application/json',
      };

      const response = await fetch(apiUrl,
        {
          method: 'GET',
          headers,
        }
      )

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      parseResponse(await response.json());

    }catch(error) {
      console.log(error)
      document.location.href = config.errorRedirectUrl;
    }
  }

  const getApiUrl = () => {
    const url = new URL(document.location.href);
    const search = new URLSearchParams(atob(url.search.substring(1)));
    const apiUrl = search.get('apiUrl');
    const domain = new URL(apiUrl).hostname;
    if(!config.allowedBEDomains.includes(domain)) {
        throw new Error('domain not allowed');
    }
    return apiUrl;
  }

  useEffect(()=>{
    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    if(!loaded) {
      try{
        const apiUrl = getApiUrl();
        if(apiUrl) {
          getInfo(apiUrl);
        } else {
          console.log('no apiUrl found')
          document.location.href = config.errorRedirectUrl;
        }
        setLoaded(true);
      } catch(e) {
        console.log(e)
       // document.location.href = config.errorRedirectUrl;
      }
    }
    return () =>
      window.removeEventListener('resize',updateDimensions);

  },[])


  const getProcessingDates = (blends) => {
    const step02Blends = blends.filter(b=>statuses.STEP_02 === b.status);
    const step09Blends = blends.filter(b=>statuses.STEP_09 === b.status);

    return buildDates(step02Blends[0], step09Blends[0])

  }

  const getSeasoningDates = (blends) => {
    const step09Blends = blends.filter(b=>statuses.STEP_09 === b.status);
    const finalizedBlend = blends.find(b=>statuses.FINALIZED === b.status);
    return buildDates(step09Blends[0], finalizedBlend)

  }

  const buildDates = (first, second) => {
    const start = moment(first.createdAt);
    const end = moment(second.createdAt);

    return {
      startDate: start.format("DD/MM/YYYY"),
      endDate: end.format("DD/MM/YYYY"),
      duration: end.diff(start, 'days')
    }
  }

  const getEnzymes = (blends) => {
    const step02Blend = blends.filter(b=>statuses.STEP_02 === b.status).find(b => b.params.modifiers);
    return step02Blend.params.modifiers.filter(m => m.category === modifiers.ENZYMES);
  }

  const getRennet = (blends) => {
    const step02Blend = blends.filter(b=>statuses.STEP_02 === b.status).find(b => b.params.modifiers);
    return step02Blend.params.modifiers.filter(m => m.category === modifiers.RENNET);
  }

  const getTimeAndTemperature = (blends) => {
    const step02Blend = blends.filter(b=>statuses.STEP_02 === b.status).find(b => b.params.modifiers);
    return step02Blend.params.modifiers.filter(m => [modifiers.TIME, modifiers.TEMPERATURE].includes(m.category));
  }

  const getSalt = (blends) => {
    const step06Blend = blends.filter(b=>statuses.STEP_06 === b.status).find(b => b.params.modifiers);
    return step06Blend.params.modifiers.filter(m => [modifiers.SALT].includes(m.category));
  }

  const getPackaging = (blends) => {
    const step07Blend = blends.filter(b=>statuses.STEP_07 === b.status).find(b => b.params.modifiers);
    return step07Blend.params.modifiers.filter(m => [modifiers.PACKAGING].includes(m.category));
  }

  const parseResponse = (res) => {
    const blends = res.result.data.blends;
    const processingDates = getProcessingDates(blends);
    const seasoningDates = getSeasoningDates(blends);
    const enzymes = getEnzymes(blends);
    const rennet = getRennet(blends);
    const timeAndTemperature = getTimeAndTemperature(blends);
    const packaging = getPackaging(blends);
    const salt = getSalt(blends);
    const blendOrigins = blends.filter(b=>b.origin);
    let origins = _.uniqWith(blendOrigins, (a, b)=>a.origin.id===b.origin.id);
    origins = origins.map( u => {
      const blends = blendOrigins.filter(b=>b.origin.id === u.origin.id);
      u.origin.amount = blends.reduce((a,b) => a + b.params.quantity, 0);
      u.origin.blendRetrievalLocation = blends.find(b=>b.params.location)?.params.location
      return u.origin;
    })


    setInfo({
      chainCode: blends.slice(-1)[0].chainCodes[0],
      productName: blends.slice(-1)[0].product.name,
      originsCount: origins.length,
      origins,
      processingDates,
      seasoningDates,
      enzymes,
      rennet,
      timeAndTemperature,
      packaging,
      salt,
      blends,
      trademark: getTrademark(res.result.data.processingFactoryIdentifier, blends)
    })
  }

  const getTrademark = (processingFactoryIdentifier, blends) => {
    const years = ['C','D','A','B'];
    const months = [10,11,12,1,2,3,4,5,6,7,8,9];
    const step04Blend = blends.filter(b=>statuses.STEP_04 === b.status)[0];
    const date = moment(step04Blend.createdAt);

    return `${processingFactoryIdentifier} ${years[date.year() % 4]} ${months.findIndex(m=>m === (date.month() + 1) )}`;


  }

  const checkChain = () => {
    setBlendListShown(true);
  }

  const showOrigins = () => {
    setOriginsShown(true);
  }


  return (
    <div style={styles.root}>
      {info?.origins && <>
        <OriginList isOpen={originsShown} parent={'#root'} origins={info?.origins} close={()=>setOriginsShown(false)}/>
        <BlendList isOpen={blendListShown} blends={info.blends} close={()=>setBlendListShown(false)}/>
        <ShareDialog isOpen={shareShown} close={()=>{setShareShown(false)}}/>
      </>}

      {!info?.chainCode &&
        <div style={styles.body}>
          <Oval
            height={80}
            width={80}
            color="#4fa94d"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor="#4fa94d"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      }
      {info?.chainCode &&
      <>
        <img alt='' src={logo} style={styles.logo}/>
        <div style={styles.header}>
          <img alt='' src={blockchainLogo} style={styles.blockchainLogo}/>
          <Text variant={'h6'} color={theme.colors.darkGrey} style={styles.headerTitle}>
            {t('routes.info.header.title')}
          </Text>
          <Text variant={'p1'} color={theme.colors.midGrey} style={styles.headerContent}>
            {t('routes.info.header.content')}
          </Text>
          <div style={styles.headerFooter}>
            <Link style={{textDecoration:'underline', padding:theme.spacing['spacing-03'],marginRight:theme.spacing['spacing-03']}}variant={'h6'} onClick={()=>setShareShown(true)}>{t('routes.info.header.footer.shareButton')}</Link>
            <Button text={t('routes.info.header.footer.checkButton')} width={77} height={26} onClick={checkChain}/>
          </div>

        </div>
        <div style={{...styles.content, width, paddingLeft:paddingVal}}>
          <Text variant={'h6'} style={styles.chainCode} color={theme.colors.darkGrey}>{t('routes.info.chainCode', {chainCode:info.chainCode})} </Text>
          <Text variant={'h6'} style={styles.chainCode} color={theme.colors.darkGrey}>{t('routes.info.trademark', {trademark:info.trademark})} </Text>
          <Text variant={'h4'} style={styles.product} color={theme.colors.darkGrey}>{info.productName}</Text>
        </div>
        <div style={{...styles.content, width, alignItems:'center'}}>
          <div style={styles.stats}>
            <Card style={styles.processing}
                  header={<Text variant={'h6'} style={styles.processingHeader}>{t('routes.info.stats.processing.header')}</Text>}
                  body={<>
                    <Text variant={'h1'} style={styles.processingBodyValue}>{info.processingDates.duration}</Text>
                    <Text variant={'h6'} style={styles.processingBodyLabel}>{t('routes.info.stats.processing.body')}</Text>
                  </>}
                  footer={<Text variant={'p1'} style={styles.processingFooter}>{t('routes.info.stats.processing.footer', {startDate:info.processingDates.startDate, endDate:info.processingDates.endDate})}</Text>}
            />
            <Card style={styles.seasoning}
                  header={<Text variant={'h6'} style={styles.seasoningHeader}>{t('routes.info.stats.seasoning.header')}</Text>}
                  body={<>
                    <Text variant={'h1'} style={styles.seasoningBodyValue}>{info.seasoningDates.duration}</Text>
                    <Text variant={'h6'} style={styles.seasoningBodyLabel}>{t('routes.info.stats.seasoning.body')}</Text>
                  </>}
                  footer={<Text variant={'p1'} style={styles.seasoningFooter}>{t('routes.info.stats.seasoning.footer', {startDate:info.seasoningDates.startDate, endDate:info.seasoningDates.endDate})}</Text>}
            />
            <Card style={styles.origin}
                  header={<Text variant={'h6'} style={styles.originHeader}>{t('routes.info.stats.origin.header')}</Text>}
                  body={<>
                    <Text variant={'h1'} style={styles.originBodyValue}>{info.originsCount}</Text>
                    <Text variant={'h6'} style={styles.originBodyLabel}>{t('routes.info.stats.origin.body')}</Text>
                  </>}
                  footer={<>
                    <Text variant={'p1'} style={styles.originFooterLabel}>{t('routes.info.stats.origin.footer.label')}</Text>
                    <Button text={t('routes.info.stats.origin.footer.button')} width={77} height={26} onClick={showOrigins}/>
                  </>}
            />

          </div>
          <div style={styles.details}>
            <Text variant={'h4'} style={styles.detailsHeader} color={theme.colors.darkGrey}>{t('routes.info.details.title')}</Text>
            <div style={styles.detailsContent}>
              <div style={styles.detailsContentLeft}>
                <ModifierDetails label={t('routes.info.details.card.enzymes')} values={info.enzymes}/>
                <ModifierDetails label={t('routes.info.details.card.rennet')} values={info.rennet}/>
                <ModifierDetails label={t('routes.info.details.card.cooking')} values={info.timeAndTemperature}/>
              </div>
              <div style={styles.detailsContentRight}>
                <ModifierDetails label={t('routes.info.details.card.salting')} values={info.salt}/>
                <ModifierDetails label={t('routes.info.details.card.packaging')} values={info.packaging}/>
              </div>
            </div>
          </div>
        </div>
      </>
      }
      {error && <div>{error}</div>}
    </div>
)}

const styles = {
  root: {
    textAlign: 'center',
    display: 'flex',
    flex:1,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection:'column',
    padding:theme.spacing['spacing-05']
  },
  logo:{

  },
  header: {
    background: theme.colors.lighterGrey,
    borderRadius: 10,
    width:290,
    marginBottom: theme.spacing['spacing-06'],
    padding: theme.spacing['spacing-05'],
    display: 'flex',
    flexDirection:'column',
    alignItems: 'center',
  },
  blockchainLogo: {
    width:28,
    height:28,
  },
  headerTitle: {
    marginTop: theme.spacing['spacing-04'],
    marginBottom: theme.spacing['spacing-04'],
  },
  headerContent: {
    marginBottom: theme.spacing['spacing-05'],
  },
  headerFooter: {
    display:'flex',
    flexDirection: 'row',
    alignItems:'center',
    justifyContent: 'space-between'
  },
  content:{
    display:'flex',
    flexDirection: 'column',
    textAlign: 'left',
    paddingLeft:30,
  },
  chainCode:{
    marginBottom: theme.spacing['spacing-04'],
  },
  product:{
    marginBottom: theme.spacing['spacing-05'],
  },
  stats:{
    display:'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  processing:{
    background: theme.colors.lighterGrey,
    borderRadius: 10,
    width: 116,
    height: 109,
    marginTop: theme.spacing['spacing-04'],
  },
  processingHeader:{
    color:theme.colors.primary
  },
  processingBodyValue:{
    color:theme.colors.black,
    marginRight: theme.spacing['spacing-02'],
  },
  processingBodyLabel:{
    color:theme.colors.midGrey
  },
  processingFooter:{
    color: theme.colors.midGrey
  },
  seasoning:{
    background: theme.colors.primary,
    borderRadius: 10,
    width: 116,
    height: 109,
    marginRight: theme.spacing['spacing-04'],
    marginLeft: theme.spacing['spacing-04'],
    marginTop: theme.spacing['spacing-04'],
  },
  seasoningHeader:{
    color:theme.colors.white,
  },
  seasoningBodyValue:{
    color:theme.colors.white,
    marginRight: theme.spacing['spacing-02'],
  },
  seasoningBodyLabel:{
    color:theme.colors.white,
  },
  seasoningFooter:{
    color:theme.colors.white,
  },
  origin:{
    width: 266,
    height: 109,
    background: `url(${mapStats})`,
    borderRadius: 10,
    marginTop: theme.spacing['spacing-04'],
  },
  originHeader:{
    color:theme.colors.white,
  },
  originBodyValue:{
    color:theme.colors.white,
    marginRight: theme.spacing['spacing-02'],
  },
  originBodyLabel:{
    color:theme.colors.white,
  },
  originFooterLabel:{
    color:theme.colors.white,
    width: 120,
  },
  details:{
    marginTop: theme.spacing['spacing-07'],
  },
  detailsHeader: {
    marginBottom: theme.spacing['spacing-04'],
  },
  detailsContent: {
    display:'flex',
    flexDirection:'row',
    flexWrap: 'wrap',
  },
  detailsContentLeft: {
    marginRight: theme.spacing['spacing-04'],
  },
  detailsContentRight: {

  },
}


export default Info;
