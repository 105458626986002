import { Helmet } from 'react-helmet';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ModalProvider } from './contexts/ModalContext';
import routes from './routes'
import './translations/index'
import mainLogo from './assets/images/logo.png'

import ReactGA from 'react-ga4'

ReactGA.initialize('G-VCDG2PN7ZS')

function App() {

  return (
    <BrowserRouter>
      <ModalProvider>
        <Helmet>
          <title>Unblended tracciamento agroalimentare con blockchain - Unblended</title>
          <meta name="description"
                content="Tracciamento d'origine, risparmio di tempo e azzeramento degli errori, miglioramento della produzione, riduzione dei tempi di raccolta e del costo carburante, valorizzazione del territorio."/>

            <meta itemProp="name" content="Unblended tracciamento agroalimentare con blockchain - Unblended"/>
            <meta itemProp="description" content="Tracciamento d'origine, risparmio di tempo e azzeramento degli errori, miglioramento della produzione, riduzione dei tempi di raccolta e del costo carburante, valorizzazione del territorio."/>
            <meta itemProp="image" content={mainLogo}/>


          <meta property="og:url" content={window.location.href}/>
          <meta property="og:type" content="website"/>
          <meta property="og:title"
                content="Unblended tracciamento agroalimentare con blockchain - Unblended"/>
          <meta property="og:description"
                content="Tracciamento d'origine, risparmio di tempo e azzeramento degli errori, miglioramento della produzione, riduzione dei tempi di raccolta e del costo carburante, valorizzazione del territorio."/>
          <meta property="og:image"
                content={mainLogo}/>


          <meta name="twitter:card" content="summary_large_image"/>
          <meta name="twitter:title"
                content="Unblended tracciamento agroalimentare con blockchain - Unblended"/>
          <meta name="twitter:description"
                content="Tracciamento d'origine, risparmio di tempo e azzeramento degli errori, miglioramento della produzione, riduzione dei tempi di raccolta e del costo carburante, valorizzazione del territorio."/>
          <meta name="twitter:image"
                content={mainLogo}/>

        </Helmet>
        <Routes>
            <Route path="/" element={routes.info}/>
            <Route path="/register" element={routes.register}/>
            <Route path="/resetPassword" element={routes.register}/>
        </Routes>
      </ModalProvider>
    </BrowserRouter>
  );
}

export default App;
