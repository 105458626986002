import React from 'react';
import {theme} from "../../theme";
const Link = (props) => {
    const {variant} = props
    return (
        <a
            style={{...styles.root, ...theme.components.Text.variants[variant], ...props.style}}
            onClick={props.onClick}
        >
            {props.children}
        </a>
    );
};

const styles = {
    root: {
        color:theme.colors.primary,
        textDecoration:'none',
        cursor:'pointer',
    }
}

export default Link;
