import React, {useState} from 'react';
import {theme} from "../../theme";

const Button = (props) => {
    const {text, disabled, background, width, height, textAlign} = props
    const [clicked, setClicked] = useState(false)

    return (
        <div>
            <button
                onMouseDown={() => setClicked(!clicked)}
                onMouseUp={() => setClicked(!clicked)}
                style={{...styles(background, clicked, disabled, textAlign).root, width, height}}
                {...props}>
                {text}
            </button>
        </div>
    );
};

const styles =(background, clicked, disabled, textAlign) => {
  return {
    root: {
       backgroundColor: background ? background : theme.colors.primary,
       borderRadius: 55,
       color: theme.colors.white,
       paddingLeft: theme.spacing["spacing-04"],
       paddingRight: theme.spacing["spacing-04"],
       ...theme.components.Text.variants.h6,
       height: 38,
       minWidth: 100,
       alignItems: 'center',
       justifyContent: 'center',
       borderColor: 'transparent',
       cursor: 'pointer',
       opacity: (clicked || disabled) ? 0.5 : 1,
       textAlign: textAlign || '',
    }
  }
}
export default Button;
