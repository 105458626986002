import react, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { modifiers, statuses } from '../../const';
import { useEffectOnce } from '../../hooks/useEffectOnce';
import { theme } from '../../theme';
import Button from './Button';
import Card from './Card';
import Text from './Text';
import config from '../../config'
import moment from 'moment';


const BlendDetails = ({blend}) => {
    const {t} = useTranslation();
    const [info, setInfo] = useState(null);
    useEffectOnce(()=>{
        setInfo({
            actor: getActor(),
            action:getAction(),
            date: moment(blend.updatedAt).format("DD/MM/YYYY hh:mm"),
            link: config.blockchainScanSite + blend.blockchainTxHash
        });
    }, [])

    const getActor = () => {
        return blend.owner.name + ` (${t('routes.info.blendDetails.roles.'+blend.owner.role)})`;
    }

    const getAction = () => {

        if(blend.origin){
            const date = moment(blend.createdAt);
            return <Text variant={'default_text'} style={styles.action}>{t('routes.info.blendDetails.actions.deliver', {origin: blend.origin.name, date: date.format("DD/MM/YYYY"), time:date.format('hh:mm')})}</Text>
        }

        if(blend.status === statuses.STEP_02){
            if(blend.params.modifiers?.find(m=>m.category===modifiers.TEMPERATURE)) {
                return <Text variant={'default_text'} style={styles.action}>{t('routes.info.blendDetails.actions.step-02.ingredients')}</Text>

            } else {
                return <Text variant={'default_text'} style={styles.action}>{t('routes.info.blendDetails.actions.step-02.default')}</Text>
            }
        }

        if(blend.status === statuses.STEP_04){
            return <>
                <Text variant={'default_text'} style={styles.action}>{t('routes.info.blendDetails.actions.step-04.default')}</Text>
                <Text variant={'h5b'} style={{...styles.action, marginLeft:5}}>{t('routes.info.blendDetails.actions.step-04.tradeMark')}</Text>
            </>
        }

        return <Text variant={'default_text'} style={styles.action}>{t('routes.info.blendDetails.actions.'+blend.status)}</Text>
    }


    return !info ? <></> : (
      <Card style={styles.root}
        header={<Text variant={'h6'} style={styles.actor}>{info.actor}</Text>}
        body={getAction()}
        footer={<div style={styles.footer}>
            <Text variant={'h6'} color={theme.colors.midGrey} style={styles.date}>{info.date}</Text>
            <Button text={t('routes.info.blendDetails.button')} width={77} height={30} onClick={()=>window.open(info.link, '_blank', 'noopener,noreferrer')}/>

        </div>}
        />
    );
};

const styles = {
    root: {
        backgroundColor: theme.colors.lighterGrey,
        marginBottom:theme.spacing['spacing-04'],
        borderRadius:10,
        padding: theme.spacing['spacing-04'],
    },
    actor:{
        textAlign: 'left',

    },
    action:{
        textAlign: 'left',

    },
    date:{
        textAlign: 'left',

    },
    link: {

    },
    footer: {
        display:'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width:'100%',
        alignItems:'center',
    }
}

export default BlendDetails;
