import React from 'react';
import { theme } from '../../theme';

const Card = (props) => {
    const {header, body, footer} = props
    return (
        <div style={{...props.style, ...styles.root}}>
            <div style={styles.header}>{header}</div>
            <div style={styles.body}>{body}</div>
            <div style={styles.footer}>{footer}</div>
        </div>
    );
};

const styles = {
    root: {
        textAlign: 'left',
        position:'relative',
        padding:theme.spacing['spacing-04'],
    },
    header:{
        marginBottom:theme.spacing['spacing-05'],
    },
    body:{
        display:'flex',
        flexDirection:'row',
        justifyContent: 'left',
        alignItems:'flex-end',
        marginBottom:theme.spacing['spacing-06'],
    },
    footer:{
        display:'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
}

export default Card;
